// @flow
import type { CourseItem } from "redux/api-types/CourseItem";
import type { CurriculumModuleItem } from "redux/api-types/CurriculumModuleItem";
import type { Employee } from "redux/api-types/Employee";
import type { Organization } from "redux/api-types/Organization";
import type { MetadataObj } from "shared/constants/flowTypes";
import type {
  simpleReduxAction,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import type { callApiReturnType } from "redux/middleware/api";
import { CALL_API } from "redux/middleware/api";
import { parseFormValues } from "./parsers/form";

import {
  SET_MODE,
  SET_ID,
  CURRICULA_REQUEST,
  CURRICULA_REQUEST_SUCCESS,
  CURRICULA_REQUEST_ERROR,
  RANGES_REQUEST,
  RANGES_REQUEST_SUCCESS,
  RANGES_REQUEST_ERROR,
  ITEMS_REQUEST,
  ITEMS_REQUEST_SUCCESS,
  ITEMS_REQUEST_ERROR,
  SET_SPECIAL_SELECT,
  SET_SPECIAL_MULTISELECT,
  SET_STEP,
  SET_COMPLETED_STEPS,
  NEXT_STEP,
  PREVIOUS_STEP,
  POST_COURSE_REQUEST,
  POST_COURSE_SUCCESS,
  POST_COURSE_ERROR,
  RESET_SELECT,
  CLEAR,
} from "./createCourseActions";

import { getJwtUser } from "shared/utilities/authCookie";

// Action Flow Types
export type SetDescriptionReturn = {
  type: string,
  payload: {
    description: string,
  },
};

export type SetModeReturn = {
  type: string,
  payload: {
    mode: string,
  },
};

export type SetIdReturn = {
  type: string,
  payload: {
    id: string,
  },
};

export type ItemResponse = {
  data: Array<CourseItem>,
  included: Array<CurriculumModuleItem>,
};

export type ReceiveItemsAction = {
  type: string,
  payload: ItemResponse,
  meta: {
    receivedAt: number,
  },
};

export type RangesResponse = {
  data: Employee,
  included: Array<Organization>,
};

export type ReceiveRangesAction = {
  type: string,
  payload: RangesResponse,
  meta: {
    receivedAt: number,
  },
};

export type Curriculum = {
  id: string,
  attributes: {
    name: string,
    description: string,
    image: string,
    categoryId: string,
    createdAt: string,
    updatedAt: string,
  },
  links: {
    _self: string,
  },
  type: string,
};

export type curriculaReponse = {
  data: Array<Curriculum>,
};

export type receiveCurriculaAction = {
  type: string,
  payload: curriculaReponse,
  meta: {
    receivedAt: number,
  },
};

export type specialSelectValueType = {
  label: string,
  value: string,
};

export type setSpecialSelectType = {
  type: string,
  payload: {
    field: string,
    value: specialSelectValueType,
  },
};

export type setSpecialSelectFunctionType = (
  field: string,
  value: specialSelectValueType
) => setSpecialSelectType;

export type setSpecialMultiSelectType = {
  type: string,
  payload: {
    field: string,
    values: Array<specialSelectValueType>,
  },
};

export type setSpecialMultiSelectFunctionType = (
  field: string,
  values: Array<specialSelectValueType>
) => setSpecialMultiSelectType;

export type setStepAction = {
  type: string,
  payload: {
    step: number,
  },
};

export type setCompletedStepsAction = {
  type: string,
  payload: {
    completedSteps: number,
  },
};

export type CourseSubmission = {
  instructorId: string,
  name: string,
  curriculumId?: string,
  curriculumModuleId?: string,
  tags?: Array<string>,
  description?: string,
  notes?: string,
  location: string,
  address1: string,
  address2?: string,
  city: string,
  state: string,
  postalCode: string,
  cost: number,
  capacity: number,
  classroomHours: number,
  rangeHours: number,
  hasLiveFire: boolean,
  grantsCCW: boolean,
  environment: string,
  isWheelchairAccessible: boolean,
  isWomensOnly: boolean,
  items: Array<MetadataObj>,
  registrationPhone?: string,
  registrationEmail?: string,
  registrationUrl?: string,
  isPublished: boolean,
  registrationType: string,
};

export type requestPostCourseAction = {
  type: string,
  payload: {
    course: CourseSubmission,
  },
};

export type CourseReponse = {
  data: {
    id: string,
    attributes: {
      capacity: string,
      classroomHours: string,
      createdAt: string,
      description: string,
      hasLiveFire: boolean,
      grantsCCW: boolean,
      environment: string,
      isWheelchairAccessible: boolean,
      isWomensOnly: boolean,
      location: {
        name: string,
        address1: string,
        address2: string,
        city: string,
        state: string,
        postalCode: string,
        lat: number,
        lng: number,
      },
      name: string,
      notes: string,
      price: number,
      rangeHours: string,
      registration: {
        email: string,
        phone: string,
        url: string,
      },
      updatedAt: string,
    },
    links: {
      _self: string,
    },
    type: string,
  },
};

export type PostCourseSuccessAction = {
  type: string,
  payload: {
    course: CourseSubmission,
  },
  meta: {
    receivedAt: number,
  },
};

// Action Creators
export const setMode = (mode: string): SetModeReturn => ({
  type: SET_MODE,
  payload: {
    mode,
  },
});
export const setId = (id: string): SetIdReturn => ({
  type: SET_ID,
  payload: {
    id,
  },
});

export const requestCurricula = (): simpleReduxAction => ({
  type: CURRICULA_REQUEST,
});

export const receiveCurricula = (
  json: curriculaReponse
): receiveCurriculaAction => ({
  type: CURRICULA_REQUEST_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestCurriculaError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: CURRICULA_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: undefined,
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestRanges = (): simpleReduxAction => ({
  type: RANGES_REQUEST,
});

export const receiveRanges = (json: RangesResponse): ReceiveRangesAction => ({
  type: RANGES_REQUEST_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestRangesError = (error: apiErrorParams): apiErrorAction => ({
  type: RANGES_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: undefined,
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestItems = (): simpleReduxAction => ({
  type: ITEMS_REQUEST,
});

export const receiveItems = (json: ItemResponse): ReceiveItemsAction => ({
  type: ITEMS_REQUEST_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestItemsError = (error: apiErrorParams): apiErrorAction => ({
  type: ITEMS_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: undefined,
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const setSpecialSelect = (
  field: string,
  value: specialSelectValueType
): setSpecialSelectType => ({
  type: SET_SPECIAL_SELECT,
  payload: {
    field,
    value,
  },
});

export const setSpecialMultiSelect = (
  field: string,
  values: Array<specialSelectValueType>
): setSpecialMultiSelectType => ({
  type: SET_SPECIAL_MULTISELECT,
  payload: {
    field,
    values,
  },
});

export const setStep = (step: number): setStepAction => ({
  type: SET_STEP,
  payload: {
    step,
  },
});

export const setCompletedSteps = (
  completedSteps: number
): setCompletedStepsAction => ({
  type: SET_COMPLETED_STEPS,
  payload: {
    completedSteps,
  },
});

export const nextStep = (): simpleReduxAction => ({
  type: NEXT_STEP,
});

export const previousStep = (): simpleReduxAction => ({
  type: PREVIOUS_STEP,
});

export const resetSelectVals = (): simpleReduxAction => ({
  type: RESET_SELECT,
});

export const requestPostCourse = (
  course: CourseSubmission
): requestPostCourseAction => ({
  type: POST_COURSE_REQUEST,
  payload: {
    course,
  },
});

export const postCourseSuccess = (
  json: CourseReponse
): PostCourseSuccessAction => ({
  type: POST_COURSE_SUCCESS,
  payload: {
    course: json,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const postCourseError = (error: apiErrorParams): apiErrorAction => ({
  type: POST_COURSE_ERROR,
  error: true,
  payload: {
    errorMessage: undefined,
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async actions
const { userId = "" } = getJwtUser();

export const getCurricula = (): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "GET",
    endpoint: `/api/training/instructors/${userId}/curricula?include=curriculumModules`,
    actions: {
      request: requestCurricula,
      success: receiveCurricula,
      failure: requestCurriculaError,
    },
    toasts: {
      failure: "There was a problem fetching your curricula",
    },
  },
});

export const getItems = (): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "GET",
    endpoint: "/api/training/course-items",
    actions: {
      request: requestItems,
      success: receiveItems,
      failure: requestItemsError,
    },
    toasts: {
      failure: "There was a problem loading course items",
    },
  },
});

export const getInstructorRanges = (userId: string): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "GET",
    endpoint: `/api/orgs/employees/${userId}/organizations?include=organizations`,
    actions: {
      request: requestRanges,
      success: receiveRanges,
      failure: requestRangesError,
    },
    disableErrorToast: true,
    toasts: {
      failure: "There was a problem loading ranges",
    },
  },
});

export const postCourse = (course: CourseSubmission): callApiReturnType => {
  const options = {
    ...course,
    instructorId: userId,
  };
  const data = parseFormValues(options);
  return {
    type: CALL_API,
    payload: {
      method: "POST",
      endpoint: "/api/training/courses",
      options: data,
      actions: {
        request: requestPostCourse,
        success: postCourseSuccess,
        failure: postCourseError,
      },
      toasts: {
        success: "Course Created",
        failure: "Couldn't Create Course",
      },
    },
  };
};
