// @flow
import InstructorRecord from "./InstructorRecord";
import type { InstructorRecordType } from "./InstructorRecord";
import type { Instructor as InstructorType } from "redux/api-types/Instructor";
import type { Include as IncludeType } from "redux/api-types/Include";

export const parseInstructorIncluded = (
  included: IncludeType
): InstructorRecordType => {
  let imageUrl = "";
  if (included && included[0]) {
    imageUrl = included[0].attributes.url;
  }

  const Record = new InstructorRecord({
    imageUrl,
  });

  return Record;
};

export const parseInstructor = (data: InstructorType): InstructorRecordType => {
  const {
    id,
    attributes: {
      number: instructorNumber,
      status,
      isRangePartner,
      firstName,
      lastName,
      companyName,
      biography,
      email,
      phone,
      address1,
      address2,
      city,
      state,
      postalCode,
      averageReviewRating,
      totalReviewCount,
      hasPaymentEnabled,
    },
  } = data;
  const initials = firstName.charAt(0) + lastName.charAt(0);

  const Record = new InstructorRecord({
    id,
    status,
    isRangePartner,
    instructorNumber,
    firstName,
    lastName,
    initials,
    companyName,
    email,
    biography,
    phone,
    address1,
    address2,
    city,
    state,
    postalCode,
    averageReviewRating,
    totalReviewCount,
    hasPaymentEnabled,
  });

  return Record;
};

export const getBlankRecord = (): InstructorRecordType => {
  const Record = new InstructorRecord({});

  return Record;
};
