// @flow
import React from "react";
import type { Node } from "react";
import { FormGroup, InputGroup, RadioGroup, Radio } from "@blueprintjs/core";
import FormFieldError from "./FormFieldError";

type Meta = {
  touched: boolean,
  error: boolean,
};

type Input = {
  name: string,
  value: string,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
};

// Type definition for individual option in RenderRadioField
type Option = {
  label: string,
  value: string,
  disabled?: boolean,
  description?: string,
};

type Props = {
  containerClass: string,
  inputClass: string,
  input: Input,
  label: string,
  labelinfo: string,
  helperText: string,
  type: string,
  placeholder: string,
  disabled: boolean,
  leftIcon?: string,
  meta: Meta,
};

let RenderField = ({
  containerClass,
  inputClass,
  input,
  placeholder,
  disabled,
  label,
  labelinfo,
  helperText,
  type,
  leftIcon,
  meta: { touched, error },
}: Props): Node => (
  <FormGroup
    className={containerClass}
    label={label}
    labelinfo={labelinfo}
    helperText={helperText}
    labelFor={input.name}
  >
    <InputGroup
      className={inputClass}
      type={type}
      {...input}
      leftIcon={leftIcon}
      disabled={disabled}
      placeholder={placeholder}
    />
    {touched && error && <FormFieldError>{error}</FormFieldError>}
  </FormGroup>
);

RenderField.defaultProps = {
  containerClass: "",
  inputClass: "",
  label: "",
  labelinfo: "",
  helperText: "",
  type: "text",
  placeholder: "",
};

export default RenderField;

// New component for rendering radio buttons
type RadioFieldProps = {
  label: string,
  name: string,
  options: Array<Option>,
  input: Input,
  meta: Meta,
};

export const RenderRadioField = ({
  label,
  name,
  options,
  input,
  meta: { touched, error },
}: RadioFieldProps): Node => (
  <FormGroup label={label}>
    <RadioGroup
      name={name}
      onChange={input.onChange}
      selectedValue={input.value}
    >
      {options.map((option: Option): Node => (
        <Radio
          label={option.label}
          value={option.value}
          disabled={option.disabled}
        />
      ))}
    </RadioGroup>
    {touched && error && <FormFieldError>{error}</FormFieldError>}
  </FormGroup>
);
