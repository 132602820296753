// @flow
import { Record } from "immutable";

export type CurriculumRecordI = {
  id: string,
  name: string,
  abbreviation: string,
  description: string,
  minimumPrice: number,
  flatFee: number,
  curriculumModules: mixed,
  liveFireOptional: boolean,
  liveFireProhibited: boolean,
  liveFireRequired: boolean,
  hasStateRestriction: boolean,
  requiresModule: boolean,
  searchableProhibited: boolean,
  searchableOptional: boolean,
  searchableRequired: boolean,
  webPayOnly: number,
};

const CurriculumRecord = Record({
  id: "",
  name: "",
  abbreviation: "",
  description: "",
  minimumPrice: 0,
  flatFee: 0,
  curriculumModules: [],
  liveFireOptional: true,
  liveFireProhibited: false,
  liveFireRequired: false,
  hasStateRestriction: false,
  requiresModule: false,
  searchableProhibited: null,
  searchableOptional: null,
  searchableRequired: null,
  webPayOnly: 0,
});

export default CurriculumRecord;
