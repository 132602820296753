// @flow
import type { Course } from "redux/api-types/Course";
import type { InitialFormValues } from "redux/modules/editCourse";
import { VOID } from "shared/constants/form";
import { PROVIDED, EQUIPMENT } from "shared/constants/items";

type FormItems = {
  [string]: {
    id: string,
    value: boolean | string,
  },
};

type ParsedItems = {
  [string]: boolean | string,
};

const prependItems = (prepend: string, items: FormItems): ParsedItems => {
  const parsedItems = {};
  const ids = Object.keys(items);

  ids.forEach(function (id: string) {
    const item = items[id];
    const key = `${prepend}-${id}`;
    parsedItems[key] = item.value;
  });

  return parsedItems;
};

export const parseInitialCourseFormValues = (
  course: Course,
  imageId: string,
  imageUrl: string
): InitialFormValues => {
  const {
    name,
    overrideUrl,
    isSearchable,
    curriculumId,
    curriculumModuleId,
    price,
    capacity,
    description,
    notes,
    organizationId,
    classroomHours,
    rangeHours,
    hasLiveFire,
    grantsCCW,
    environment,
    isWomensOnly,
    isWheelchairAccessible,
    location: { name: location, address1, address2, city, state, postalCode },
    registration: {
      url: registrationUrl,
      email: registrationEmail,
      phone: registrationPhone,
    },
    bringableItems,
    providedItems,
    registrationType,
  } = course.attributes;

  const bringables = prependItems(EQUIPMENT, bringableItems);
  const provided = prependItems(PROVIDED, providedItems);

  const courseFormValues = {
    name,
    overrideUrl,
    isSearchable,
    curriculumId: curriculumId || VOID,
    curriculumModuleId,
    price,
    capacity,
    description,
    notes,
    organizationId,
    classroomHours,
    rangeHours,
    hasLiveFire,
    grantsCCW,
    location,
    address1,
    address2,
    city,
    state,
    postalCode,
    registrationUrl,
    registrationEmail,
    registrationPhone,
    environment,
    isWomensOnly,
    isWheelchairAccessible,
    imageId,
    imageUrl,
    registrationType,
    ...bringables,
    ...provided,
  };

  return courseFormValues;
};
