// @flow
import { APP_NAMESPACE } from "shared/constants/application";

//Actions
const namespace: string = `${APP_NAMESPACE}/createCourse`;

export const SET_MODE = `${namespace}/MODE/SET`;
export const SET_ID = `${namespace}/ID/SET`;
export const CURRICULA_REQUEST = `${namespace}/CURRICULA_REQUEST/FETCH`;
export const CURRICULA_REQUEST_SUCCESS = `${namespace}/CURRICULA_REQUEST/SUCCESS`;
export const CURRICULA_REQUEST_ERROR = `${namespace}/CURRICULA_REQUEST/ERROR`;
export const RANGES_REQUEST = `${namespace}/RANGES/FETCH`;
export const RANGES_REQUEST_SUCCESS = `${namespace}/RANGES/SUCCESS`;
export const RANGES_REQUEST_ERROR = `${namespace}/RANGES/ERROR`;
export const ITEMS_REQUEST = `${namespace}/ITEMS_REQUEST/FETCH`;
export const ITEMS_REQUEST_SUCCESS = `${namespace}/ITEMS_REQUEST/SUCCESS`;
export const ITEMS_REQUEST_ERROR = `${namespace}/ITEMS_REQUEST/ERROR`;
export const SET_SPECIAL_SELECT = `${namespace}/SPECIAL_SELECT/SET`;
export const SET_SPECIAL_MULTISELECT = `${namespace}/SPECIAL_MULTISELECT/SET`;
export const SET_STEP = `${namespace}/STEPSET`;
export const SET_COMPLETED_STEPS = `${namespace}/COMPLETED_STEPS/SET`;
export const NEXT_STEP = `${namespace}/STEP/NEXT`;
export const PREVIOUS_STEP = `${namespace}/STEP/PREVIOUS`;
export const POST_COURSE_REQUEST = `${namespace}/POST/REQUEST`;
export const POST_COURSE_SUCCESS = `${namespace}/POST/SUCCESS`;
export const POST_COURSE_ERROR = `${namespace}/POST/ERROR`;
export const RESET_SELECT = `${namespace}/RESET/SELECT`;
export const CLEAR = `${namespace}/CLEAR`;
export const INSTRUCTOR_REQUEST = `${namespace}/INSTRUCTOR_REQUEST`;
export const INSTRUCTOR_REQUEST_SUCCESS = `${namespace}/INSTRUCTOR_REQUEST`;
export const INSTRUCTOR_REQUEST_ERROR = `${namespace}/INSTRUCTOR_REQUEST_ERROR`;
