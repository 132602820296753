// @flow
import { EQUIPMENT, PROVIDED } from "shared/constants/items";

const isPrepended = (prepend: string, name: string): boolean => {
  const namePrepend = name.substring(0, prepend.length);
  return prepend === namePrepend;
};

const getTrueName = (prepend: string, name: string): string => {
  // It's prepend plus hyphen, so add one more char
  const stringStart = prepend.length + 1;
  const trueName = name.substring(stringStart);
  return trueName;
};

type ValuesType = {
  instructorId: string,
  isPublished: boolean,
  isWomensOnly: boolean,
  name: string,
  curricula: ?string,
  tags?: Array<string>,
  description: string,
  notes?: string,

  location: string,
  address1: string,
  address2?: string,
  city: string,
  state: string,
  postalCode: string,
  isWheelchairAccessible: boolean,
  environment: string,

  price: string,
  capacity: string,
  classroomHours?: string,
  rangeHours?: string,
  grantsCCW?: boolean,
  hasLiveFire?: boolean,

  registrationEmail: string,
  registrationPhone: string,
  registrationUrl: string,
  registrationType: string,
};

type ValuesReturnType = {
  bringableItems: {},
  providedItems: {},
} & ValuesType;

export const parseFormValues = (values: ValuesType): ValuesReturnType => {
  let providedItems = {};
  let bringableItems = {};

  const keys = Object.keys(values);

  keys.forEach(function (key: string) {
    const item = values[key];
    const isEquipment = isPrepended(EQUIPMENT, key);
    const isProvided = isPrepended(PROVIDED, key);

    if (isEquipment) {
      const id = getTrueName(EQUIPMENT, key);
      bringableItems[id] = {
        id,
        value: item,
      };
    } else if (isProvided) {
      const id = getTrueName(PROVIDED, key);
      providedItems[id] = {
        id,
        value: item,
      };
    }

    if (isEquipment || isProvided) {
      delete values[key];
    }
  });
  return {
    ...values,
    providedItems,
    bringableItems,
  };
};
