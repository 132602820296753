// @flow
import { handleActions } from "redux-actions";

import {
  LOADING,
  PENDING,
  LOADED,
  SUCCESS,
  ERROR,
} from "shared/constants/status";

import { NONE, EDIT, NO_MODE } from "./constants";

import type {
  simpleReduxAction,
  selectOption,
  apiErrorReturn,
  apiErrorAction,
} from "shared/constants/flowTypes";

import type {
  ReceiveRangesAction,
  ReceiveItemsAction,
  SetIdReturn,
  SetModeReturn,
  receiveCurriculaAction,
  requestPostCourseAction,
  setCompletedStepsAction,
  setSpecialMultiSelectType,
  setSpecialSelectType,
  setStepAction,
  specialSelectValueType,
} from "./createCourseActionCreators";
import {
  parseInstructorRanges,
  parseInstructorRangeOptions,
} from "./parsers/instructorRanges";
import {
  parseInstructorCurricula,
  parseInstructorCurriculaModules,
  parseInstructorCurriculaToOptions,
  parseInstructorCurriculaModulesOptions,
} from "./parsers/instructorCurricula";
import { parseItems } from "./parsers/items";
import { CALL_API } from "redux/middleware/api";

import {
  SET_MODE,
  SET_ID,
  CURRICULA_REQUEST,
  CURRICULA_REQUEST_SUCCESS,
  CURRICULA_REQUEST_ERROR,
  RANGES_REQUEST,
  RANGES_REQUEST_SUCCESS,
  RANGES_REQUEST_ERROR,
  ITEMS_REQUEST,
  ITEMS_REQUEST_SUCCESS,
  ITEMS_REQUEST_ERROR,
  SET_SPECIAL_SELECT,
  SET_SPECIAL_MULTISELECT,
  SET_STEP,
  SET_COMPLETED_STEPS,
  NEXT_STEP,
  PREVIOUS_STEP,
  POST_COURSE_REQUEST,
  POST_COURSE_SUCCESS,
  POST_COURSE_ERROR,
  RESET_SELECT,
  CLEAR,
  INSTRUCTOR_REQUEST,
  INSTRUCTOR_REQUEST_SUCCESS,
  INSTRUCTOR_REQUEST_ERROR,
} from "./createCourseActions";

import steps from "./createCourseSteps";

// Initial State
export type stepType = {
  key: string,
  name: string,
  title: string,
};

export type SelectValsI = {
  curriculumId: specialSelectValueType | null,
  curriculumModuleId: specialSelectValueType | null,
  tags: Array<specialSelectValueType> | null,
};

export type State = {
  completedSteps: number,
  currentStep: number,
  curricula: {},
  curriculaModules: {},
  curriculaModulesOptions: Array<selectOption>,
  curriculaOptions: Array<selectOption>,
  curriculaReceivedAt: number,
  id: string,
  items: Array<mixed>,
  itemsReceivedAt: number,
  mode: string,
  postCourseErrorMessage: string,
  postCourseErrors: apiErrorReturn,
  postCourseStatus: string,
  requestCurriculaErrorMessage: string,
  requestCurriculaErrors: apiErrorReturn,
  requestCurriculaStatus: string,
  requestRangesErrorMessage: string,
  requestRangesErrors: apiErrorReturn,
  requestRangesStatus: string,
  ranges: {},
  rangeOptions: Array<selectOption>,
  requestItemsErrorMessage: string,
  requestItemsErrors: apiErrorReturn,
  requestItemsStatus: string,
  selectVals: SelectValsI,
  steps: Array<stepType>,
  totalSteps: number,
  minimumPrice: number,
  hasPaymentEnabled: boolean,
  instructorHasPaymentEnabled: boolean | null,
  instructorRequestStatus: string,
  instructorReqeustErrorMessage: string,
  instructorRequestErrors: apiErrorReturn,
};

const initialState: State = {
  mode: NO_MODE,
  id: "",
  steps,
  currentStep: 0,
  totalSteps: steps.length,
  completedSteps: 0,
  requestCurriculaStatus: NONE,
  requestCurriculaErrorMessage: "",
  requestCurriculaErrors: [],
  curricula: {},
  curriculaModules: {},
  curriculaOptions: [],
  curriculaModulesOptions: [],
  curriculaReceivedAt: 0,
  requestRangesStatus: NONE,
  requestRangesErrorMessage: "",
  requestRangesErrors: [],
  ranges: {},
  rangeOptions: [],
  requestItemsStatus: NONE,
  requestItemsErrorMessage: "",
  requestItemsErrors: [],
  items: [],
  itemsReceivedAt: 0,
  postCourseStatus: NONE,
  postCourseErrorMessage: "",
  postCourseErrors: [],
  minimumPrice: 0.5,
  registrationType: "uscca",
  selectVals: {
    curriculumId: null,
    curriculumModuleId: null,
    tags: null,
    state: null,
  },
  hasPaymentEnabled: false,
  instructorHasPaymentEnabled: null,
  instructorRequestStatus: NONE,
  instructorReqeustErrorMessage: "",
  instructorRequestErrors: [],
};

// Types

// Selectors
export const getRequestCurriculaStatus = ({
  createCourse: { requestCurriculaStatus },
}: {
  createCourse: State,
}): string => requestCurriculaStatus;

export const getSetCurricula = ({
  createCourse: { curricula },
}: {
  createCourse: State,
}): {
  [string]: {
    name: string,
  },
} => curricula;

export const getSetCurriculaModules = ({
  createCourse: { curriculaModules },
}: {
  createCourse: State,
}): {
  [string]: {
    name: string,
  },
} => curriculaModules;

export const getRequestRangesStatus = ({
  createCourse: { requestRangesStatus },
}: {
  createCourse: State,
}): string => requestRangesStatus;

export const getRanges = ({
  createCourse: { ranges },
}: {
  createCourse: State,
}): {
  [string]: {},
} => ranges;

export const requestInstructor = (
  uuid: string
): RequestInstructorReturnType => ({
  type: INSTRUCTOR_REQUEST,
  payload: {
    uuid,
  },
});

export const receiveInstructor = (
  json: InstructorApiReturn
): InstructorReturn => ({
  type: INSTRUCTOR_REQUEST_SUCCESS,
  payload: {
    instructor: json,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const instructorRequestError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: INSTRUCTOR_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: undefined,
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

// Async actions
export const getCourseInstructor = (id: string): callApiReturnType => {
  return {
    type: CALL_API,
    payload: {
      method: "GET",
      endpoint: `/api/training/instructors/${id}?include=images`,
      actions: {
        request: requestInstructor,
        success: receiveInstructor,
        failure: instructorRequestError,
      },
    },
  };
};

// Reducer
const createCourse = handleActions(
  {
    [INSTRUCTOR_REQUEST]: (
      state: State,
      action: RequestInstructorReturnType
    ): State => {
      return {
        ...state,
        instructorHasPaymentEnabled: null,
      };
    },
    [INSTRUCTOR_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveInstructorReturnType
    ): State => {
      const hasPaymentEnabled =
        action.payload.instructor?.data.attributes.hasPaymentEnabled;
      return {
        ...state,
        instructorHasPaymentEnabled: hasPaymentEnabled,
      };
    },
    [INSTRUCTOR_REQUEST_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const {
        payload: { errorMessage, errors },
      } = action;
      return {
        ...state,
        instructorHasPaymentEnabled: null,
        instructorRequestStatus: ERROR,
        instructorReqeustErrorMessage: errorMessage,
        instructorRequestErrors: errors,
      };
    },
    [SET_MODE]: (state: State, action: SetModeReturn): State => {
      const { mode } = action.payload;
      const completedSteps =
        mode === EDIT ? steps.length : state.completedSteps;
      return {
        ...state,
        mode,
        completedSteps,
      };
    },
    [SET_ID]: (state: State, action: SetIdReturn): State => {
      const { id } = action.payload;
      return {
        ...state,
        id,
      };
    },
    [CURRICULA_REQUEST]: (state: State, action: simpleReduxAction): State => ({
      ...state,
      requestCurriculaStatus: LOADING,
      requestCurriculaErrorMessage: "",
      requestCurriculaErrors: [],
      curricula: {},
      curriculaOptions: [],
      curriculaReceivedAt: 0,
    }),
    [CURRICULA_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveItemsAction
    ): State => {
      const {
        payload: { data, included },
        meta: { receivedAt },
      } = action;
      const curriculaOptions = parseInstructorCurriculaToOptions(data);
      const curricula = parseInstructorCurricula(data);
      const curriculaModulesOptions = included
        ? parseInstructorCurriculaModulesOptions(included)
        : [];
      const curriculaModules = included
        ? parseInstructorCurriculaModules(included)
        : {};
      return {
        ...state,
        requestCurriculaStatus: LOADED,
        curricula,
        curriculaModules,
        curriculaOptions,
        curriculaModulesOptions,
        curriculaReceivedAt: receivedAt,
      };
    },
    [CURRICULA_REQUEST_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const {
        payload: { errorMessage, errors },
      } = action;

      return {
        ...state,
        requestCurriculaStatus: ERROR,
        requestCurriculaErrorMessage: errorMessage,
        requestCurriculaErrors: errors,
      };
    },
    [RANGES_REQUEST]: (state: State, action: simpleReduxAction): State => ({
      ...state,
      requestRangesStatus: LOADING,
      requestRangesErrorMessage: "",
      requestRangesErrors: [],
      ranges: {},
      rangeOptions: [],
    }),
    [RANGES_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveRangesAction
    ): State => {
      const ranges = parseInstructorRanges(action.payload);
      const rangeOptions = parseInstructorRangeOptions(action.payload);
      return {
        ...state,
        requestRangesStatus: LOADED,
        ranges,
        rangeOptions,
      };
    },
    [RANGES_REQUEST_ERROR]: (state: State, action: apiErrorAction): State => {
      const {
        payload: { errorMessage, errors },
      } = action;
      // if instructor is not an employee of any org, lets not show it as an error
      const is404 = errors.length && errors[0].status === 404;
      const update = is404
        ? { requestRangesStatus: LOADED }
        : {
            requestRangesStatus: ERROR,
            requestRangesErrorMessage: errorMessage,
            requestRangesErrors: errors,
          };

      return {
        ...state,
        ...update,
      };
    },
    [ITEMS_REQUEST]: (state: State, action: simpleReduxAction): State => ({
      ...state,
      requestItemsStatus: LOADING,
      requestItemsErrorMessage: "",
      requestItemsErrors: [],
      items: [],
      itemsReceivedAt: 0,
    }),
    [ITEMS_REQUEST_SUCCESS]: (
      state: State,
      action: receiveCurriculaAction
    ): State => {
      const {
        payload: { data },
        meta: { receivedAt },
      } = action;
      const items = parseItems(data);

      return {
        ...state,
        requestItemsStatus: LOADED,
        items,
        itemsReceivedAt: receivedAt,
      };
    },
    [ITEMS_REQUEST_ERROR]: (state: State, action: apiErrorAction): State => {
      const {
        payload: { errorMessage, errors },
      } = action;

      return {
        ...state,
        requestItemsStatus: ERROR,
        requestItemsErrorMessage: errorMessage,
        requestItemsErrors: errors,
      };
    },
    [SET_SPECIAL_SELECT]: (
      state: State,
      action: setSpecialSelectType
    ): State => {
      const {
        payload: { field, value },
      } = action;
      const { selectVals } = state;
      let tempSelectVals = selectVals;
      if (selectVals.curriculumModuleId && field === "curriculumId") {
        tempSelectVals = {
          ...tempSelectVals,
          curriculumModuleId: null,
        };
      }
      // Set minimum price for validation
      const curricula =
        value.value && value.value.length > 2
          ? state.curricula[value.value]
          : "";
      const validationMinimumPrice = curricula ? curricula.minimumPrice : 0;
      if (curricula) {
        return {
          ...state,
          minimumPrice: validationMinimumPrice,
          selectVals: {
            ...tempSelectVals,
            [field]: value,
          },
        };
      }
      return {
        ...state,
        selectVals: {
          ...tempSelectVals,
          [field]: value,
        },
      };
    },
    [SET_SPECIAL_MULTISELECT]: (
      state: State,
      action: setSpecialMultiSelectType
    ): State => {
      const {
        payload: { field, values },
      } = action;
      const { selectVals } = state;
      return {
        ...state,
        selectVals: {
          ...selectVals,
          [field]: values,
        },
      };
    },
    [SET_STEP]: (state: State, action: setStepAction): State => {
      const {
        payload: { step },
      } = action;

      return {
        ...state,
        currentStep: step,
      };
    },
    [SET_COMPLETED_STEPS]: (
      state: State,
      action: setCompletedStepsAction
    ): State => {
      const {
        payload: { completedSteps },
      } = action;

      return {
        ...state,
        completedSteps,
      };
    },
    [NEXT_STEP]: (state: State, action: simpleReduxAction): State => {
      const { currentStep, totalSteps } = state;
      const nextStep = currentStep + 1;
      const step = nextStep === totalSteps ? currentStep : nextStep;

      return {
        ...state,
        currentStep: step,
      };
    },
    [PREVIOUS_STEP]: (state: State, action: simpleReduxAction): State => {
      const { currentStep } = state;
      const prevStep = currentStep - 1;
      const step = currentStep === 0 ? currentStep : prevStep;

      return {
        ...state,
        currentStep: step,
      };
    },
    [POST_COURSE_REQUEST]: (
      state: State,
      action: requestPostCourseAction
    ): State => ({
      ...state,
      postCourseStatus: PENDING,
    }),
    [POST_COURSE_SUCCESS]: (
      state: State,
      action: postCourseSuccessAction
    ): State => ({
      ...state,
      postCourseStatus: SUCCESS,
    }),
    [POST_COURSE_ERROR]: (state: State, action: apiErrorAction): State => {
      const {
        payload: { errorMessage, errors },
      } = action;

      return {
        ...state,
        postCourseStatus: ERROR,
        postCourseError: true,
        postCourseErrorMessage: errorMessage,
        postCourseErrors: errors,
      };
    },
    [RESET_SELECT]: (state: State, action: simpleReduxAction): State => ({
      ...state,
      selectVals: {
        curriculumId: null,
        curriculumModuleId: null,
        tags: null,
        state: null,
      },
    }),
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default createCourse;
